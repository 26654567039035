<template>
  <div class="flex flex-wrap max-w-180 items-center">
    <template v-if="truncatedList?.length">
      <template v-if="listItemType === 'badge'">
        <lf-badge
          v-for="item in truncatedList"
          :key="item"
          :is-rounded="rounded"
          truncate
          data-cy="badge-list-item"
        >
          {{ options ? (options[item] ?? item) : item }}
        </lf-badge>
      </template>
      <template v-else>
        <div class="flex flex-col">
          <div
            v-for="item in truncatedList"
            :key="item"
            :is-rounded="rounded"
            class="text-gray-500 mb-3"
            data-cy="badge-list-item"
          >
            {{ options ? (options[item] ?? item) : item }}
          </div>
        </div>
      </template>
      <tooltip interactive @show="() => additionalCount > 0">
        <template #default>
          <lf-pill
            v-if="additionalCount > 0"
            size="sm"
            class="uppercase font-bold ml-1 cursor-pointer"
            :color-index="listItemType === 'badge' ? 15 : undefined"
          >
            +{{ additionalCount }}
          </lf-pill>
        </template>
        <template #content>
          <div class="text-left whitespace-pre-wrap max-h-44 overflow-auto">
            {{ additionalItemsDescription }}
          </div>
        </template>
      </tooltip>
    </template>
    <span v-else>-</span>
  </div>
</template>

<script setup lang="ts">
import LfBadge from "@/components/ui/LfBadge.vue";
import { computed, type PropType } from "vue";

const props = defineProps({
  value: {
    type: Array as PropType<(number | string)[]>,
    default: () => []
  },
  options: {
    type: Object as PropType<Record<string, unknown>>
  },
  rounded: {
    type: Boolean,
    default: false
  },
  showNumOfItems: {
    type: Number
  },
  listItemType: {
    type: String,
    default: "badge"
  }
});

const truncatedList = computed(() =>
  props.showNumOfItems
    ? props.value.slice(0, props.showNumOfItems)
    : props.value
);

const additionalCount = computed(() =>
  props.showNumOfItems && props.value.length > 1
    ? props.value.length - props.showNumOfItems
    : 0
);

const additionalItemsDescription = computed(() => {
  if (!props.showNumOfItems || !Array.isArray(props.value)) {
    return "";
  }

  const items = props.value.slice(props.showNumOfItems);
  const displayTextList = items?.map((item) => {
    return props.options ? (props.options[item] ?? item) : item;
  });

  return items?.length ? displayTextList.join("\n") : "";
});
</script>
